@import '../../variables';

a.MuiButton {
  &-root {
    &:link, &:hover, &:active, &:focus {
      text-transform: none;
      color: $textColor;
      font-weight: $weightMedium;
    }
  }
}
