@import '../../../../assets/styles/variables';

.Panel {
  display: flex;
  flex-direction: column;
  background-color: $white;
  border: 1px solid $borderColor;
  border-radius: $radiusBase;
  padding: $gridSpacingX;
}
.PanelTitle {
  font-weight: $weightBold;
  text-transform: uppercase;
  font-size: (16/$df-fs)*$rem;
  padding-bottom: ($gridSpacingX/2);
}
.PanelContent {
  flex: 1 1 0;
  flex-basis: 0;
  overflow-y: auto;
  padding-right: 16px;
  padding-left: 16px;
  margin-right: -16px;
  margin-left: -16px;
}
.PanelFooter {
  padding-top: ($gridSpacingX/2);
  button {
    display: block;
    width: 100%;
  }
}
