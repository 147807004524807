@import '../../../assets/styles/variables';

.Wrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: $gridSpacingX*2;
  align-items: start;

  .Info {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: $gridSpacingX;

    > label {
      display: block;
      font-weight: $weightSemibold;
      color: $textMuted;
      min-width: (110/$df-fs)*$rem;
    }

    & + .Info {
      padding-top: $gridSpacingY/(1.5);
    }

    &.SecondaryInfo {
      padding-top: $gridSpacingY;
      & + .SecondaryInfo {
        padding-top: $gridSpacingY/(3);
      }
    }
    &.CodeInfo {
      align-items: center;
    }
    &.Center {
      align-items: center;
    }
  }

  .CustomerCol {
    grid-column: 1 / 3;
  }
}
