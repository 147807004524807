@import '../../../assets/styles/variables';

.PageWrap {
  height: 100%;
  padding: 0 $gridSpacingX;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
}
.PageHeader {
  margin-bottom: $gridSpacingX;
  .NavContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.PageFilter {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: $gridSpacingX;
  align-items: center;
  padding: $gridSpacingY/2 0;
}
.PageTitle {
  font-size: $fontSizeLg;
  font-weight: $weightSemibold;
}
.PageMain {
  margin-bottom: $gridSpacingX;
  height: 100%;
  flex: 1 1 0;
  flex-basis: 0;
}
.PageFooter {
  display: flex;
  justify-content: flex-end;
  padding-bottom: $gridSpacingY;
}
