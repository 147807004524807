@import '../../../../assets/styles/variables';

.Item {
  background-color: $white;
  padding-top: 0;
  padding-bottom: 0;
  cursor: default;
  align-items: stretch;

  > div[class*=Col] {
    padding-top: (6 / $df-fs) * $rem;
    padding-bottom: (6 / $df-fs) * $rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-wrap: wrap;

    .MultiFieldsWrap {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
      column-gap: $gridSpacingX;
      align-items: center;

      &.CompareWrap {
        grid-template-columns: auto 1fr;
      }
      &.DiscountWrap {
        grid-template-columns: auto 2fr 1.5fr;
        row-gap: $gridSpacingY/2;

        .FieldControl {
          display: grid;
          grid-template-columns: auto 1fr;
          column-gap: ($gridSpacingX/2);
          align-items: center;

          &.LabelAfter {
            grid-template-columns: 1fr auto;
          }

          &.MaximumField {
            grid-column: 2 / 5;
            grid-row: 2 / 3;
          }
        }
      }
    }

    &.Highlighted {
      background-color: $lightYellow;
    }

    .Invalid {
      &, input {
        color: $danger;

        &::placeholder {
          color: $placeholder;
        }
      }
    }
  }
  .Checkbox {
    padding: 0;
    margin-top: (-2 / $df-fs) * $rem;
  }
  .ActionsCol {
    text-align: center;
    justify-content: center;
  }
  .Field {
    width: 100%;
  }
  &:nth-of-type(2n) {
    background-color: $whiteGrey;
  }
}
