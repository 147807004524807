@import '../../../../assets/styles/variables';

.Actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: $gridSpacingY*1.5;

  button + button {
    margin-left: $gridSpacingX;
  }
}
.Wrap {
  .PromotionName {
    font-size: $fontSizeLg;
    font-weight: $weightNormal;
    text-decoration: underline;
    padding-bottom: (2/$df-fs)*$rem;
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:hover {
      text-decoration: none;
    }
  }
  .List {
    max-height: 53vh;
    overflow-y: auto;
    margin-top: $gridSpacingY;
    margin-right: -16px;
    padding-right: 16px;
  }
}
