@import '../../../assets/styles/variables';

.Dialog {
  .DialogContentWrap{
    width: (600/$df-fs)*$rem;
    max-width: 600px;
  }
  .DialogContent {
    padding-top: 0 !important;
  }
  .DialogActions {
    padding-bottom: $gridSpacingY*1.5;
    padding-left: 24px;
    padding-right: 24px;

    button + button {
      margin-left: $gridSpacingX;
    }
  }
}

.FieldControl {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  justify-content: end;
  width: 100%;
  padding-bottom: $gridSpacingY;

  > label {
    font-weight: $weightSemibold;
    margin-right: $gridSpacingX;

    &.Flex {
      display: flex;
      align-items: center;
    }
  }
  .Valid {
    input {
      color: $success;
    }
  }
  .InValid {
    input {
      color: $danger;
    }
  }

  &.OneColumn {
    grid-template-columns: 1fr;
  }
}
.WarningIcon {
  color: $warning;
  margin-left: (4/$df-fs)*$rem;
}
