@import '../../../../assets/styles/variables';

.Dialog {
  .DialogContentWrap {
    width: (640 / $df-fs) * $rem;
    max-width: 600px;
  }
  .DialogActions {
    padding-bottom: ($gridSpacingY * 2);
    padding-left: 24px;
    padding-right: 24px;

    button + button {
      margin-left: $gridSpacingX;
    }

    &.DialogActionsFlex {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .DialogActionsLeft {
        flex: 1 1 0;
        flex-basis: 0;
      }
    }
  }
}
.FieldControl {
  padding-bottom: $gridSpacingY;
  > label {
    margin-right: $gridSpacingX;
  }

  &:last-child {
    padding-bottom: 0;
  }
}
.HelpText {
  grid-column: 2 / 3;
  margin-top: 0;
  li {
    font-size: $fontSizeSm;
    color: $textMuted;
    &.Error {
      color: $danger;
    }
  }
}
.Error {
  color: $danger;
}
.Success {
  color: $success;
}
.HelpBlock {
  padding-top: $gridSpacingY;
  p {
    padding-bottom: ($gridSpacingY/2);

    &:empty {
      display: none;
    }
  }
}
.Info {
  font-weight: $weightSemibold;
  padding-bottom: ($gridSpacingY/2);

  > label {
    font-weight: $weightNormal;
  }
}
.ImportConfirmTitle {
  font-weight: $weightSemibold;
  color: $success;
  padding-bottom: $gridSpacingY;
}
