@import '../../../../assets/styles/variables';

.Wrap {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  background-color: $secondaryBg;
  padding: $gridSpacingY $gridSpacingX;
}
.Inner {
  flex: 1 1 0;
  flex-basis: 0;
  overflow-y: auto;
}
.Bullet {
  display: block;
  width: 9px;
  height: 9px;
  background-color: $secondaryColor;
  border-radius: 50%;
}
.EventList {
  position: relative;
  z-index: 0;
  min-height: 100%;

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    height: 100%;
    left: 4px;
    width: 1px;
    background-color: $textMuted;
    opacity: 0.33;
  }
}
.EventItem {
  display: flex;
  padding-bottom: (1.5 * $gridSpacingY);

  .EventDetails {
    padding-left: ($gridSpacingX/2);
  }
  .EventItem {
    flex: 1 1 0;
    padding-left: (2 * $gridSpacingX);
    word-break: break-word;
  }
  .EventTime {
    color: $textMuted;
    font-size: $fontSizeSm;
    font-weight: $weightBold;
    padding-bottom: (6/$df-fs)*$rem;
    line-height: 1;
  }
  .EventTitle {
    font-weight: $weightMedium;
  }
}
