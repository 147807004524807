@import '../../../assets/styles/variables';

.List {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  background-color: $white;
  box-shadow: $boxShadow;
  border-radius: $radiusBase;

  .Checkbox {
    padding: 0;
  }
}
.Header {
  font-weight: 600;
  box-shadow: -4px 4px 5px 0px $boxHeader;
  border-bottom: 1px solid transparent;
  background-color: $headerBg;
  align-items: center;
  color: $headerColor;
  border-top-left-radius: $radiusBase;
  border-top-right-radius: $radiusBase;
}
.Body {
  height: 100%;
  flex: 1 1 0;
  flex-basis: 0;
  overflow-y: auto;
  overflow-x: hidden;
  border-bottom-left-radius: $radiusBase;
  border-bottom-right-radius: $radiusBase;
}
