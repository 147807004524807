@import '../../../assets/styles/variables';

.Dialog {
  .DialogContentWrap {
    width: 90vw;
    max-width: 1300px;
    height: 90vh;
    display: flex;
    flex-direction: column;
  }
  .DialogTitle {
    padding: $gridSpacingY/1.5 0;
  }
  .DialogContent {
    flex: 1 1 0;
    flex-basis: 0;
    padding: 0 !important;
    height: 100%;

    display: grid;
    grid-template-columns: 4fr 8fr;
    align-items: stretch;

    background-color: $secondaryBg;
  }

  .DialogContentSide,
  .DialogContentMain {
    padding: $gridSpacingY/1.5 $gridSpacingX*1.5 $gridSpacingY;
    display: flex;
    flex-direction: column;

    .TitleWrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .Title {
      font-size: $fontSizeLg;
      padding-bottom: $gridSpacingY/2;
      font-weight: $weightSemibold;
    }
    .Content {
      flex: 1 1 0;
      flex-basis: 0;
      overflow-y: auto;
      overflow-x: hidden;
      padding-right: 16px;
      margin-right: -16px;
    }
    .Actions {
      text-align: right;
      padding: $gridSpacingY $gridSpacingX 0;
      button + button {
        margin-left: $gridSpacingX;
      }
    }
  }

  .DialogContentMain {
    background-color: $bodyBg;
    border-left: 1px solid $borderColor;
  }
}
