@import '../../../../assets/styles/variables';

.Wrap {
  padding-bottom: $gridSpacingY;

  .SelectionGroup {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: $gridSpacingY;
    align-items: stretch;

    .SelectionItem {
      background-color: $white;
      padding: $gridSpacingY;
      padding-left: $gridSpacingY/2;
      border: 1px solid $borderSecondaryColor;
      border-radius: $radiusLg;
      margin-left: 0;
      margin-right: 0;
      display: grid;
      grid-template-columns: auto 1fr;
      align-items: flex-start;
      font-size: $fontSizeBase;

      .TextExplained {
        color: $textMuted;
        font-size: $fontSizeSm;
        padding-top: (3/$df-fs)*$rem;
        padding-bottom: (3/$df-fs)*$rem;
      }

      .ServiceSelected {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        .Fee {
          font-size: $fontSizeBase;
          color: $textColor;
        }
      }
    }
  }

  .Actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: $gridSpacingY*1.5;

    button + button {
      margin-left: $gridSpacingX;
    }
  }

  .WarningInfo {
    color: $dangerText;
    font-size: $fontSizeBase;
    padding-bottom: $gridSpacingY/2;
  }
}
