@import '../../../../assets/styles/variables';

.TooltipIcon {
  color: $iconColor;
  margin-left: $gridSpacingX/3;
  vertical-align: text-bottom;
  cursor: pointer;
}
.Content {
  padding: $gridSpacingX/2;
  p {
    color: $white;
    font-size: $fontSizeSm;

    & + p {
      padding-top: (8/$df-fs)*$rem;
    }
  }
}