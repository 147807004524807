@import '../../../assets/styles/variables';

.PageWrap {
  height: 100%;
  padding: 0 $gridSpacingX;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
}
.PageHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: $gridSpacingX;

  button {
    margin-left: $gridSpacingX;
  }
}
.PageTitle {
  font-size: $fontSizeH2;
}
.PageMain {
  margin-bottom: $gridSpacingX;
  height: 100%;
  flex: 1 1 0;
  flex-basis: 0;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: (220 / $df-fs) * $rem 1fr;
  grid-column-gap: $gridSpacingX;
}
.PageFooter {
  display: flex;
  justify-content: flex-end;
  padding-bottom: $gridSpacingY;
}
