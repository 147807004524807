@import '../../../../assets/styles/variables';

.Item {
  background-color: $white;
  cursor: pointer;
  user-select: none;

  &:nth-of-type(2n) {
    background-color: $whiteGrey;
  }

  .OrderMostItemsCol {
    p + p {
      padding-top: (4/$df-fs)*$rem;
    }
    span {
      color: $textMuted;
      font-weight: $weightSemibold;
    }
  }

  .LatestNoteCol {
    font-style: italic;
    color: $textMuted;
  }

  .Checkbox {
    padding: 0;
  }

  .CustomerCode {
    color: $textMuted;
    font-size: $fontSizeSm;
    font-weight: $weightSemibold;
  }

  &:hover {
    color: inherit;
  }
}
