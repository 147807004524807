@import '../../assets/styles/variables';

.PermissionDenied {
  padding: $gridSpacingY*4;
  margin: 0 auto;
  text-align: center;

  .Heading {
    font-size: $fontSizeH2;
    padding-bottom: $gridSpacingY*2;
  }

  .LogoWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: $gridSpacingY*2;

    > .Logo {
      display: block;
      max-width: (120/$df-fs)*$rem;
      max-height: (120/$df-fs)*$rem;
      width: auto;
      height: auto;
    }
  }

  .Button {
    display: block;
    width: 52%;
    margin: 0 auto;
    background-color: $primaryColor;
    color: $white;
    font-size: $fontSizeBase;
    line-height: 1.44;
    padding: (10/$df-fs)*$rem;
    text-transform: uppercase;
    border-radius: $radiusBase;
    font-weight: $weightBold;
    box-shadow: 0px 2px 4px rgba(204, 0, 0, 0.2), 0px 4px 8px rgba(237, 28, 36, 0.2);
    &:hover {
      box-shadow: none;
      background-color: $primaryHoverColor;
    }
    &:focus {
      box-shadow: none;
      background-color: $primaryPressColor;
    }
  }
}
