@import '../../../../../assets/styles/variables';

.FieldControlWrap {
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: $gridSpacingX;
  align-items: start;
  width: 100%;

  .Field {
    display: flex;
  }

  .Actions {
    button {
      min-width: (136/$df-fs)*$rem;
    }
  }
}
