@import '../../../../assets/styles/variables';

.Dialog {
  .DialogTitle {
    padding: $gridSpacingY $gridSpacingY*1.5;
  }
  .DialogContent {
    padding: 0 $gridSpacingY*1.5 $gridSpacingY*1.5;
  }
  .DialogContentWrap {
    min-width: (640/$df-fs) * $rem;
    width: 50vw;
    max-width: none;
  }

  .DialogInfo {
    text-align: center;
    width: 320px;
    padding: $gridSpacingY;

    p, .DialogInfoTitle, .DialogInfoIcon {
      padding-bottom: $gridSpacingY/1.5;
    }
    .DialogInfoIcon {
      color: $success;
      font-size: (40/$df-fs) * $rem;
      padding-bottom: $gridSpacingY/2;
    }
    .DialogInfoTitle {
      font-size: $fontSizeLg;
      font-weight: $weightSemibold;
    }
    .DialogInfoActions {
      button {
        display: block;
        width: 100%;
      }
    }
    .OrderInfo {
      border-top: 1px dotted $secondaryBg;
      padding-bottom: $gridSpacingY/2;

      .Info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: $weightSemibold;
        padding: $gridSpacingY/2 0 0;

        > label {
          padding-right: $gridSpacingX/2;
          color: $textMuted;
          font-weight: $weightNormal;
        }
        .Amount {
          font-size: $fontSizeLg;
          color: $primaryColor;
        }
      }
    }
    .OrderRating {
      margin-bottom: $gridSpacingY;
      border-radius: $radiusBase;
      background-color: $secondaryBg;
      padding: $gridSpacingY/2 $gridSpacingX;

      > label {
        display: block;
        padding-bottom: $gridSpacingY/2;
        text-align: left;
      }
      .RatingBox {
        display: grid;
        grid-template-columns: 1fr auto;
        column-gap: $gridSpacingX/2;

        .RatingLabel {
          width: (100/$df-fs)*$rem;
          text-align: center;
          border-left: 1px solid $borderSecondaryColor;
          margin-left: $gridSpacingX/2;
        }
        .Rating > label {
          width: (32/$df-fs)*$rem;
          text-align: center;
        }
      }
    }
  }
}