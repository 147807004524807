@import '../variables';

html {
  height: 100%;
  overflow: hidden;
}

body {
  height: 100%;
  overflow: auto;
  background: $bodyBg;
  color: $textColor;
  font-size: (14 / $df-fs) * $rem;
  line-height: 1.44;
  font-family: $fontFamilyBase;
}

a {
  &,
  &:link {
    color: $textColor;
    text-decoration: none;
  }
  &:hover {
    color: $primaryHoverColor;
    text-decoration: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: $weightNormal;
  margin: 0;
  padding: 0;
}

ul,
dl,
ol,
ul li,
dl li,
ol li {
  margin: 0;
  padding: 0;
  list-style: none;
  list-style-type: none;
}

p {
  margin: 0;
  padding: 0;
}

textarea {
  font-family: $fontFamilyBase;
  font-size: $fontSizeBase;
  color: $textColor;
  width: 100%;
  border: 0;
  border-bottom: 2px solid rgba(0, 0, 0, 0.33);
  margin: 0;
  display: block;
  padding: 0 0 7px;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  animation-name: mui-auto-fill-cancel;
  letter-spacing: inherit;
  animation-duration: 10ms;
  -webkit-tap-highlight-color: transparent;
  outline: none;
  resize: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
input[type=number] {
  -moz-appearance:textfield; /* Firefox */
}
input::-ms-reveal,
input::-ms-clear {
  display: none !important;
}

strong, b {
  font-weight: $weightBold;
}

button {
  text-transform: none !important;
}

.OverlayLoading {
  position: relative;
  z-index: 9;

  &:after {
    content: ' ';
    display: block;
    position: absolute;
    z-index: 9;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba($color: $grey, $alpha: 0.5);
    background-image: url(../../images/loading.gif);
    background-position: center center;
    background-repeat: no-repeat;
  }
}
.NoData {
  color: $textMuted;
  padding: $gridSpacingY;
  text-align: center;
}

.Badge {
  border-radius: 999px;
  padding: 0 (4/$df-fs)*$rem (1/$df-fs)*$rem;
  min-width: (10/$df-fs)*$rem;
  color: $white;
  font-weight: $weightBold;
  font-size: $fontSizeXs;
  display: inline-block;
  line-height: 1.6;
  background-color: $secondaryColor;
  margin-right: (4/$df-fs)*$rem;
  text-align: center;

  &.BlueGrey {
    background-color: $blueGrey;
    color: $textColor;
  }
  &.Text {
    font-weight: $weightSemibold;
    font-size: $fontSizeSm;
    line-height: 1.4;
    margin: (2/$df-fs)*$rem 0;
    text-align: left;
    border-radius: $radiusXs;
  }
}

.PreWrap {
  word-break: break-word;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
}

.PageTitle {
  font-size: $fontSizeH1;
  font-weight: $weightSemibold;
  color: $textColor;
  padding-bottom: $gridSpacingY/1.5;
}
.SectionTitle {
  font-size: $fontSizeXLg;
  font-weight: $weightSemibold;
  color: $textColor;
  padding-bottom: $gridSpacingY/1.5;
}

.Breadscrumbs {
  font-size: $fontSizeSm;
  padding-bottom: $gridSpacingY/2;

  &,
  li,
  li a {
    font-family: $fontFamilyBase;
  }

  li {
    font-size: $fontSizeBase;
    color: $textMuted;

    a {
      &, &:link, &:active, &:focus {
        color: $textMuted;
        line-height: 1.44;
        text-decoration: underline;
      }

      &:hover {
        text-decoration: none;
        color: $textMuted;
      }
    }
    .BreadscrumbActive {
      color: $textColor;
      font-weight: $weightSemibold;
    }

    .BreadscrumbSeparator {
      font-size: $fontSizeLg;
    }

    &.MuiBreadcrumbs-separator {
      margin-left: $gridSpacingX/2;
      margin-right: $gridSpacingX/2;
    }
  }
}

.ExtraPrimaryBtn {
  font-weight: $weightSemibold;
  width: 48%;
  background-color: $primaryColor;
  box-shadow: 0px 2px 4px rgba(204, 0, 0, 0.2), 0px 4px 8px rgba(237, 28, 36, 0.2);
  &:hover {
    box-shadow: none;
    background-color: $primaryHoverColor;
  }
  &:focus {
    box-shadow: none;
    background-color: $primaryPressColor;
  }
}
