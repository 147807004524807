@import '../../../../assets/styles/variables';

.Panel {
  margin-bottom: $gridSpacingY;
}
.Wrap {
  .Info {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    > label {
      display: block;
      color: $textMuted;
      padding-right: $gridSpacingX/3;
    }

    &.HelpLine {
      color: $textMuted;
      font-size: $fontSizeSm;
      justify-content: flex-end;
      padding-top: 0 !important;
      font-weight: $weightNormal;
    }

    & + .Info {
      padding-top: $gridSpacingY/2;
    }

    &.InfoTotal {
      margin-top: $gridSpacingY/2;
      border-top: 1px dotted $borderColor;
      align-items: flex-end;

      .Amount {
        font-size: $fontSizeLg;
        color: $primaryColor;
        font-weight: $weightSemibold;
      }
    }
  }
}

