@import '../../../assets/styles/variables';

.Row {
  padding: (6 / $df-fs) * $rem (8 / $df-fs) * $rem;
  display: grid;
  align-items: center;
  grid-template-columns: (30/$df-fs)*$rem 1.5fr 1.5fr 0.75fr 0.75fr;
  grid-template-rows: auto;

  &.LineDiscountRow,
  &.DiscountRow {
    grid-template-columns: (30/$df-fs)*$rem 1.5fr 3fr;
    border-top: none;
    padding-top: 0;

    .GroupCol {
      padding: $gridSpacingY/2 0;
      display: grid;
      grid-template-columns: 1fr auto;
      column-gap: $gridSpacingX;
    }
  }
  &.DiscountRow {
    grid-template-columns: (30/$df-fs)*$rem 4.5fr;
  }

  &.RowVendor {
    grid-template-columns: (30/$df-fs)*$rem 1.5fr 1.5fr 0.75fr 1.25fr 0.75fr 1.5fr;

    &.LineDiscountRow,
    &.DiscountRow {
      grid-template-columns: (30/$df-fs)*$rem 1.5fr 5.75fr;
      border-top: none;
      padding-top: 0;

      .GroupCol {
        padding: $gridSpacingY/2 0;
        display: grid;
        grid-template-columns: 1fr auto;
        column-gap: $gridSpacingX;
      }
    }
    &.DiscountRow {
      grid-template-columns: (30/$df-fs)*$rem 7.75fr;
    }
  }
}
.Col {
  display: block;
  word-break: break-word;
  white-space: pre-wrap;
  padding-left: (8 / $df-fs) * $rem;
  padding-right: (8 / $df-fs) * $rem;
}
.ActionsCol {
  text-align: right;
  justify-content: flex-end;
}
