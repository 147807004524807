@import '../../../../../assets/styles/variables';

.NoInvoiceIcon,
.MinQuantityIcon {
  margin-left: $gridSpacingX/4;
  color: $warning;
  vertical-align: text-bottom;
  cursor: pointer;
}
.MinQuantityIcon {
  color: $danger;
  margin-left: 0;
}
.UomWrap {
  display: grid;
  grid-template-columns: 1fr (24/$df-fs)*$rem;
  column-gap: $gridSpacingX/4;
  align-items: center;
}
