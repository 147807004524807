@import '../../../../assets/styles/variables';

.Wrap {
  padding-bottom: $gridSpacingY*1.5;
}
.List {
  padding-top: ($gridSpacingY/1.5);
  border-bottom: 1px solid $borderSecondaryColor;
}
.Toolbar {
  padding-top: ($gridSpacingY/1.5);
  padding-bottom: $gridSpacingY;
  position: sticky;
  top: $mainNavHeight;
  z-index: 1;
  background-color: $bodyBg;

  & + .List {
    padding-top: 0;
  }
}
.EmptyBox {
  text-align: center;
  padding: $gridSpacingY*2 0 $gridSpacingY;
}
.HeaderToolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;

  > h3 {
    padding-bottom: 0;
  }
}
