@import '../../variables';

.MuiAutocomplete-popper {
  min-width: (200 / $df-fs) * $rem;
}
.MuiAutocomplete {
  &-inputRoot {
    .MuiAutocomplete-input:first-child {
      padding: 6px 0 !important;
    }
  }
}
