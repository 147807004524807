@import '../../../assets/styles/variables';

.Details {
  padding: 0 $gridSpacingX $gridSpacingY;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: start;
  column-gap: (2 * $gridSpacingX);
  row-gap: $gridSpacingY;

  .FieldControlWrap {
    display: flex;
    justify-content: flex-start;

    .FieldControl {
      width: auto;
      align-items: center;

      & + .FieldControl {
        margin-left: $gridSpacingX;
      }
    }
  }

  &.IsEditing {
    grid-template-columns: 1fr 1fr;
  }

}
.FieldControl {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: start;
  width: 100%;
  min-height: 30px;

  & + .FieldControl {
    padding-top: $gridSpacingY/1.5;
  }

  > label {
    font-weight: $weightSemibold;
    margin-right: $gridSpacingX;
    min-width: (120/$df-fs)*$rem;

    &.Flex {
      display: flex;
      align-items: flex-start;
    }
  }

  &.AutolabelFieldControl {
    > label {
      min-width: 0;
    }
  }

  .VendorCountBadge {
    font-size: $fontSizeBase !important;
  }
}
.ActiveWrap {
  > label {
    margin-right: $gridSpacingX;
    &:empty {
      display: none;
    }
  }
}
.WarningIcon {
  color: $warning;
  margin-left: (4 / $df-fs) * $rem;
  align-self: center;
  min-height: 0;
}
