@import '../../../assets/styles/variables';

.Panel {
  margin-top: $gridSpacingY*2;
}
.Wrap {
  .NoteItem {
    padding: $gridSpacingX/2;
    background-color: $whiteGrey;
    border-radius: $radiusSm;

    .NoteInfo {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .NoteOwner {
      font-weight: $weightSemibold;
      padding-right: $gridSpacingX/2;
    }
    .NoteDate {
      color: $textMuted;
      font-size: $fontSizeSm;
    }
    .NoteContent {
      padding-top: $gridSpacingY/4;
    }
    .NoteActions {
      align-self: flex-end;
    }

    & + .NoteItem {
      margin-top: $gridSpacingY;
    }
  }
}
