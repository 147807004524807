@import '../../../assets/styles/variables';

.PageWrap {
  padding: 0 $gridSpacingX $gridSpacingY;
}
.PageContent {
  display: grid;
  grid-template-columns: 9fr 3fr;
  column-gap: $gridSpacingX;
}
.PageMain {
  grid-column: 1 / 2;
}
.PageSubTitle {
  display: flex;
  align-items: center;
  font-size: $fontSizeSm;
  font-weight: $weightNormal;
  color: $textMuted;
  padding-top: $gridSpacingY/3;

  .CustomerName {
    text-transform: uppercase;
    font-size: $fontSizeBase;
    margin-left: $gridSpacingX/2;
    color: $textColor;
    padding: (2/$df-fs)*$rem $gridSpacingX/2;
    background-color: $secondaryBg;
    border-radius: 999px;
  }
}
.PageSide {
  grid-column: 2 / 3;
  margin-top: (40/$df-fs)*$rem;

  .PageSideInner {
    position: sticky;
    z-index: 1;
    top: ($mainNavHeight + ($gridSpacingY/1.5));
  }
}

.StepGuideControl {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto;
  column-gap: $gridSpacingX;

  padding: 0 0 $gridSpacingY*1.5;
  border-bottom: 1px solid $borderSecondaryColor;
}
.SubmitPRBtn {
  display: block;
  width: 100%;
}
