@import '../../../assets/styles/variables';

.PageWrap {
  height: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  background-color: $white;
}
.PageHeader {
  padding: 0 $gridSpacingX;
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: space-between;

  button + button {
    margin-left: $gridSpacingX;
  }
}
.PageTitle {
  font-size: $fontSizeH2;
  flex: 1 1 0;
  flex-basis: 0;
}
.PageMain {
  padding: $gridSpacingX 0 0 0;
  flex: 1 1 0;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
}
.TabsWrap {
  padding-top: $gridSpacingY;
  padding-left: $gridSpacingX;
  padding-right: $gridSpacingX;
  border-bottom: 1px solid $grey;
}
.TabsPanel {
  flex: 1 1 0;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
}
.TabLabel {
  display: flex;
  align-items: center;
  cursor: pointer;

  .HighlightMark {
    display: block;
    color: $lightYellow;
    padding-left: ($gridSpacingX/2);
  }
}
