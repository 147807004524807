@import '../../assets/styles/variables';

.AddressItem {
  font-size: $fontSizeBase;

  .CustomerName {
    font-weight: $weightSemibold;
    padding-bottom: (2/$df-fs)*$rem;

    &.MaxLine2 {
      height: (44/$df-fs)*$rem;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  .Info {
    padding: (3/$df-fs)*$rem 0;
    display: flex;
    align-items: center;
    font-size: $fontSizeSm;
    font-weight: $weightNormal;
    white-space: pre-wrap;

    p {
      &:empty {
        display: none;
      }
    }

    .Icon {
      color: $textMuted;
      margin-right: $gridSpacingX/3;
    }

    .InfoValue {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;

      &.MaxLine2 {
        -webkit-line-clamp: 2;
      }
    }
  }
  .GroupInfo {
    display: grid;
    grid-template-columns: 1fr auto auto;
    align-items: center;

    .InfoDivider {
      margin-left: $gridSpacingX;
      margin-right: $gridSpacingX;
      display: block;
      width: 1px;
      height: 16px;
      background-color: $borderSecondaryColor;
    }
    .RightInfo {
      text-align: right;
    }
  }

  &.InOneLine {
    display: flex;
    flex-wrap: wrap;

    .CustomerName {
      width: 100%;
    }
    .GroupInfo {
      padding-right: $gridSpacingX;
      margin-right: $gridSpacingX;
      position: relative;

      &:after {
        content: " ";
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        display: block;
        width: 1px;
        height: 16px;
        background-color: $borderSecondaryColor;
      }
    }
    > .Info {
      flex: 1 1 auto;
      flex-basis: auto;
    }
  }
}
