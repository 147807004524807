@import '../../../assets/styles/variables';

.PageWrap {
  height: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  background-color: $white;
}
.PageHeader {
  padding: 0 $gridSpacingX;
  background-color: $white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > button + button {
    margin-left: $gridSpacingX;
  }
}
.PageTitle {
  font-size: $fontSizeH2;
  flex: 1 1 0;
  flex-basis: 0;
}
.PageMain {
  padding: $gridSpacingX;
  flex: 1 1 0;
  flex-basis: 0;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: (220/$df-fs)*$rem 1fr;
  grid-column-gap: $gridSpacingX;
}
.PageFooter {
  display: flex;
  justify-content: flex-end;
  padding-bottom: $gridSpacingY;
}
