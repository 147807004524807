@import '../../assets/styles/variables';

.FieldWrap {
  width: 100%;
}
.AutocompleteField {
  width: 100%;
}
.EditInlineCol {
  margin-top: -6px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  button {
    margin-left: (2 / $df-fs) * $rem;
    padding: 0;
  }
  > label {
    padding-top: 6px;
    cursor: pointer;
    white-space: pre-wrap;
    word-break: break-word;
  }

  .DatePickerField {
    margin: 0;
    width: 100%;
    button {
      padding: 3px;
    }
  }
}
