@import '../../../../../assets/styles/variables';

.Dialog {
  .DialogContentWrap {
    min-width: (420 / $df-fs) * $rem;
  }
  .DialogTitle,
  .DialogContent,
  .DialogFooter {
    padding-left: (2 * $gridSpacingY);
    padding-right: (2 * $gridSpacingY);
  }
  .DialogContent {
    padding-top: 0;
    padding-bottom: (2 * $gridSpacingY);
  }
  .DialogFooter {
    padding-bottom: (2 * $gridSpacingY);

    button + button {
      margin-left: $gridSpacingX;
    }
  }
  .Logistic {
    & + .Logistic {
      padding-top: $gridSpacingY;
    }
  }
  .SelectionGroup {
    display: block;

    .SelectionItem {
      display: grid;
      grid-template-columns: auto 1fr;
      align-items: center;
      background-color: $white;
      padding: $gridSpacingY/3;
      padding-left: $gridSpacingY/2;
      border: 1px solid $borderSecondaryColor;
      border-radius: $radiusBase;
      margin-left: 0;
      margin-right: 0;
      font-size: $fontSizeBase;
      margin-top: $gridSpacingY/2;

      .Item {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
}
