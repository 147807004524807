@import '../../../../assets/styles/variables';

.Item {
  background-color: $white;
  cursor: pointer;
  user-select: none;

  &:nth-of-type(2n) {
    background-color: $whiteGrey;
  }

  &:hover {
    color: inherit;
  }
}
