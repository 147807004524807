@import '../../../../../../assets/styles/variables';

.ProductInfo {
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  column-gap: $gridSpacingX;

  .Thumb {
    & {
      width: (54/$df-fs)*$rem;
      height: (54/$df-fs)*$rem;
      overflow: hidden;
      background-color: $grey;
      text-align: center;
    }
    & > img {
      max-width: (54/$df-fs)*$rem;
      max-height: (54/$df-fs)*$rem;
      object-fit: contain;
      width: auto;
      height: auto;
      background-color: $grey;
    }
  }
  .Info {
    p {
      padding-bottom: (4/$df-fs)*$rem;
      font-weight: $weightSemibold;
    }
    .SideInfo {
      font-weight: $weightNormal;
      color: $textMuted;
      font-size: $fontSizeSm;

      .InfoValue {
        color: $textColor;
      }
    }
  }
}
.NoInvoiceIcon,
.MinQuantityIcon {
  margin-left: $gridSpacingX/4;
  color: $warning;
  vertical-align: text-bottom;
  cursor: pointer;
}
.MinQuantityIcon {
  color: $danger;
  margin-left: 0;
}
.QuantityWrap {
  position: relative;

  .QuantityWrapInner {
    display: grid;
    grid-template-columns: 1fr (24/$df-fs)*$rem;
    column-gap: $gridSpacingX/4;
    align-items: center;
  }
  .OutOfStockText {
    position: absolute;
    left: auto;
    top: auto;
    color: $primaryColor;
    font-size: $fontSizeSm;
    display: block;
    padding: $gridSpacingX/4 0;
    text-align: left;
  }
}

.ProductLine {
  .PriceWrap {
    color: $primaryColor;
  }
}

.ProductLineDisabled {
  opacity: 0.5;

  .PriceWrap {
    color: $textMuted;
  }
}
