@import '../../../assets/styles/variables';

.Panel {
  margin-top: $gridSpacingY;
}
.Wrap {
  .Info {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    font-weight: $weightSemibold;

    > label {
      display: block;
      font-weight: $weightSemibold;
      color: $textMuted;
      padding-right: $gridSpacingX/3;
    }

    &.HelpLine {
      color: $textMuted;
      font-size: $fontSizeSm;
      justify-content: flex-end;
      padding-top: 0 !important;
      font-weight: $weightNormal;
    }

    & + .Info {
      padding-top: $gridSpacingY/2;
    }

    &.InfoTotal {
      align-items: flex-end;
      font-size: $fontSizeLg;
      font-weight: $weightBold;
      .Amount {
        font-size: $fontSizeXLg;
      }
    }

    &.InfoLine {
      border-top: 1px solid $grey;
      margin-top: $gridSpacingY/2;

      &.InfoTotal {
        border-color: $primaryColor;
      }

      &:first-child {
        border-top: none;
      }
    }
  }
}

