@import '../../../../../assets/styles/variables';

.Dialog {
  .DialogContentWrap {
    min-width: (420 / $df-fs) * $rem;
  }
  .DialogTitle,
  .DialogContent,
  .DialogFooter {
    padding-left: (2 * $gridSpacingY);
    padding-right: (2 * $gridSpacingY);
  }
  .DialogContent {
    padding-top: 0;
    padding-bottom: (2 * $gridSpacingY);

    p {
      padding-bottom: 0;

      & + p {
        padding-top: $gridSpacingY;
      }
    }
  }
  .NoteField {
    display: flex;
    margin-top: $gridSpacingY;
  }
  .DialogFooter {
    padding-bottom: (2 * $gridSpacingY);

    button + button {
      margin-left: $gridSpacingX;
    }
  }
}
