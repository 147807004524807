@import '../../../assets/styles/variables';

.List {
  background-color: $white;
  border-radius: $radiusBase;
  min-height: (100/$df-fs)*$rem;
  border: 1px solid $borderColor;

  .Checkbox {
    padding: 0;
  }
}
.Header {
  font-weight: 600;
  box-shadow: -4px 4px 5px 0px $boxHeader;
  border-bottom: 1px solid transparent;
  background-color: $headerBg;
  color: $headerColor;
  border-top-left-radius: $radiusBase;
  border-top-right-radius: $radiusBase;
  padding-top: ($gridSpacingY/3);
  padding-bottom: ($gridSpacingY/3);

  .ActionsCol {
    color: $white;
    text-align: center;
    button {
      color: $white;
    }
  }
}
.Toolbar {
  padding-bottom: ($gridSpacingY/2);
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: $gridSpacingX;
  align-items: center;

  .FormGroup,
  .CustomerTypeWrap {
    display: flex;
    align-items: center;
  }
  .CustomerTypeWrap {
    > label {
      margin-right: $gridSpacingX;
    }
  }
  .FormGroup {
    flex-wrap: nowrap;
    flex-direction: row;
    > label {
      > span:first-child {
        padding: 3px;
      }
    }
  }

  button + button {
    margin-left: $gridSpacingX;
  }
}
