@import '../../../../assets/styles/variables';

.Item {
  background-color: $white;
  padding-top: 0;
  padding-bottom: 0;
  cursor: pointer;
  align-items: stretch;
  min-height: (56/$df-fs)*$rem;

  > div[class*=Col] {
    padding-top: (6 / $df-fs) * $rem;
    padding-bottom: (6 / $df-fs) * $rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &.Highlighted {
      background-color: $lightYellow;
    }
  }
  .ActionsCol {
    text-align: center;
    justify-content: center;
  }
  .Field {
    width: 100%;
  }
  &:nth-of-type(2n) {
    background-color: $whiteGrey;
  }

  &.Disabled {
    opacity: 0.4;
    position: relative;
    cursor: not-allowed;

    &:after {
      content: " ";
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: transparent;
      z-index: 9;
      left: 0;
      top: 0;
    }
  }
}
