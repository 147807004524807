@import '../../../assets/styles/variables';

.Panel {
  margin-top: $gridSpacingY*2.275;
}
.Wrap {
  .Info {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    > label {
      display: block;
      font-weight: $weightSemibold;
      color: $textMuted;
      padding-right: $gridSpacingX/3;
    }

    & + .Info {
      padding-top: $gridSpacingY/2;
    }

    &.Note {
      flex-direction: column;
      align-items: stretch;

      .NoteContent {
        flex: 1 1 0;
        flex-basis: 0;
        word-break: break-word;
        white-space: pre-wrap;
        white-space: -moz-pre-wrap;

        padding: $gridSpacingX/2;
        background-color: $whiteGrey;
        border-radius: $radiusSm;
      }
    }
  }
}
