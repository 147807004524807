@import '../../../../assets/styles/variables';

.Panel {
  margin-bottom: $gridSpacingY;
}
.ViewWrap {
  .CustomerName {
    font-weight: $weightSemibold;
    padding-bottom: (2 / $df-fs) * $rem;
  }
  .Info {
    padding: (2 / $df-fs) * $rem 0;
    display: flex;
    align-items: center;
    font-size: $fontSizeSm;
    font-weight: $weightNormal;
    word-break: break-word;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;

    p {
      &:empty {
        display: none;
      }
    }

    .Icon {
      color: $textMuted;
      margin-right: $gridSpacingX/2;
    }

    &.GroupInfo {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .InfoDivider {
        padding: 0 $gridSpacingX/4;
        display: block;
        width: 1px;
        height: 100%;
        background-color: $borderColor;
      }
    }
  }
  .VATOption {
    padding: $gridSpacingY/4 0 0;
    border-top: 1px solid $borderColor;
    margin-top: $gridSpacingY/2;
    font-size: $fontSizeBase;

    display: grid;

    .FieldControl {
      display: grid;
      grid-template-columns: auto 1fr;
      align-items: start;
      column-gap: $gridSpacingX * 1.5;

      > label {
        color: $textMuted;
        padding: $gridSpacingX/2 0 0;
      }
    }
  }
}
