@import '../../../../../assets/styles/variables';

.Toolbar {
  display: grid;
  grid-template-columns: 1fr;
}

.SearchWrap {
  position: relative;
  width: 100%;

  .SearchField {
    width: 100%;
  }
}
.ListWrap {
  padding-bottom: $gridSpacingY/2;
  width: 100%;
  position: absolute;
  left: 0;
  top: (48/$df-fs)*$rem;
  z-index: 9;

  .ProductListPanel {
    padding: $gridSpacingX/2 $gridSpacingX/2 $gridSpacingX;
  }
  .Body {
    max-height: (355/$df-fs)*$rem;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
.EmptyLine {
  height: 32px;
  margin: (24/$df-fs)*$rem auto;
  text-align: center;
  padding: $gridSpacingX;
}
