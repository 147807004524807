@import '../../../../assets/styles/variables';

.Dialog {
  .DialogContentWrap {
    width: (600 / $df-fs) * $rem;
  }
  .DialogTitle,
  .DialogContent,
  .DialogFooter {
    padding-left: (2 * $gridSpacingY);
    padding-right: (2 * $gridSpacingY);
  }
  .DialogContent {
    padding-top: 0;
    padding-bottom: 0;
    max-height: (420 / $df-fs) * $rem;
    overflow-y: auto;

    p {
      padding-bottom: 0;

      & + p {
        padding-top: $gridSpacingY;
      }
    }

    .Info {
      display: grid;
      grid-template-columns: auto 1fr;
      column-gap: (4 / $df-fs) * $rem;

      > label {
        color: $textMuted;
        width: (112 / $df-fs) * $rem;
      }

      & + .Info {
        margin-top: (12 / $df-fs) * $rem;
        padding-top: (12 / $df-fs) * $rem;
        border-top: 1px solid $secondaryBg;
      }
    }
  }
  .DialogFooter {
    padding-top: (2 * $gridSpacingY);;
    padding-bottom: (2 * $gridSpacingY);

    button {
      min-width: (140 / $df-fs) * $rem;
    }
    button + button {
      margin-left: $gridSpacingX;
    }
  }
}
