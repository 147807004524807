@import '../../../../assets/styles/variables';

.ChangePasswordWrap {
  background-color: $white;
  padding: $gridSpacingY $gridSpacingX;
  border-radius: $radiusBase;

  .ChangePassword {
    max-width: 640px;
    margin: 0 auto;
    text-align: center;
  }
  .HelperText {
    color: $textMuted;
    padding-bottom: (16/$df-fs)*$rem;
  }
  .Field {
    display: flex;
    padding-bottom: (16/$df-fs)*$rem;
  }
  .Control {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: $gridSpacingY;
    border-top: 1px solid $inputBorder;
  }
  .Divider {
    color: $inputBorder;
    font-size: $fontSizeBase;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: $gridSpacingY/2;
    padding-bottom: $gridSpacingY/2;

    &:before, &:after {
      width: 100%;
      content: " ";
      height: 1px;
      background-color: $inputBorder;
    }
  }
  .MessagesBlock {
    padding-top: $gridSpacingY/2;
  }
  .FormInner {
    padding: $gridSpacingX;
    border-radius: $radiusBase;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  }
}
