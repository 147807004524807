@import '../../../../assets/styles/variables';

.Actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: $gridSpacingY*1.5;

  button + button {
    margin-left: $gridSpacingX;
  }
}
.List {
  max-height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 16px;
  margin-right: -16px;
}
.SelectionGroup {
  .SelectionItem {
    background-color: $white;
    padding: $gridSpacingY/1.5;
    border: 1px solid $borderSecondaryColor;
    border-radius: $radiusBase;
    margin-left: 0;
    margin-right: 0;
    font-size: $fontSizeBase;
    display: flex;
    align-items: flex-start;

    .TextExplained {
      color: $textMuted;
      padding-top: (5/$df-fs)*$rem;
    }

    .PromotionLink {
      font-size: $fontSizeLg;
      text-decoration: underline;
      padding-bottom: (2/$df-fs)*$rem;
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }
    }

    & + .SelectionItem {
      margin-top: $gridSpacingY;
    }
  }
}
