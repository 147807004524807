@import '../../../../assets/styles/variables';

.Col {
  display: block;
  word-break: break-word;
  white-space: pre-wrap;
  padding-left: $gridSpacingX/2;
  padding-right: $gridSpacingX/2;

  color: $textColor;
  font-size: $fontSizeBase;
  font-weight: $weightNormal;
}
.Row {
  padding: $gridSpacingX/2 $gridSpacingX/4;
  display: grid;
  align-items: center;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  border-bottom: 1px solid $secondaryBg;

  &:last-child {
    border-bottom: none;
  }

  &.Header {
  font-weight: 600;
  box-shadow: -4px 4px 5px 0px $boxHeader;
  border-bottom: 1px solid transparent;
    padding-top: $gridSpacingY/1.5;
    padding-bottom: $gridSpacingY/1.5;
    background-color: $theadBg;
    border-bottom: none;
    border-radius: $radiusBase;

    .Col {
      color: $headerColor;
      text-transform: uppercase;
      font-size: $fontSizeSm;
      font-weight: $weightNormal;

    }
  }

  &.IsEditing {
    grid-template-columns: 2fr 2fr (108/$df-fs)*$rem 1fr (124/$df-fs)*$rem 1.5fr (60/$df-fs)*$rem;
  }
}

