@import '../../../assets/styles/variables';

.PageWrap {
  height: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  background-color: $white;
}
.PageHeader {
  padding: 0 $gridSpacingX $gridSpacingY;
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: space-between;

  button + button {
    margin-left: $gridSpacingX;
  }
}
.PageTitle {
  font-size: $fontSizeH2;
  flex: 1 1 0;
  flex-basis: 0;
}
.PageMain {
  padding: 0;
  flex: 1 1 0;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  overflow-y: auto;
}
.Wrap {
  padding: ($gridSpacingY/2) $gridSpacingX $gridSpacingY;
}
.TabHidden {
  display: none !important;
}
.SectionTitle {
  padding-bottom: $gridSpacingY/2;
  text-transform: uppercase;
  font-weight: $weightBold;
  font-size: (16/$df-fs)*$rem;
}
