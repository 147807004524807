@import '../../../../../assets/styles/variables';

.Dialog {
  .DialogTitle {
    padding: $gridSpacingY $gridSpacingY*1.5 $gridSpacingY/1.5;
  }
  .DialogContent {
    padding: 0 $gridSpacingY*1.5 $gridSpacingY*1.5;
  }
  .DialogContentWrap {
    min-width: (640/$df-fs) * $rem;
    width: 50vw;
    max-width: none;
  }

  .DialogInfo {
    text-align: center;
    width: 320px;
    padding: $gridSpacingY;

    p, .DialogInfoTitle, .DialogInfoIcon {
      padding-bottom: $gridSpacingY/1.5;
    }
    .DialogInfoIcon {
      color: $textMuted;
      font-size: (40/$df-fs) * $rem;
      padding-bottom: $gridSpacingY/2;
    }
    .DialogInfoTitle {
      font-size: $fontSizeLg;
      font-weight: $weightSemibold;
    }
    .DialogInfoActions {
      padding-top: $gridSpacingY;
      display: flex;
      align-items: center;
      justify-content: center;

      button {
        display: block;
        min-width: (100/$df-fs) * $rem;

        & + button {
          margin-left: $gridSpacingX;
        }

        &.BlockButton {
          width: 100%;
        }
      }
    }
  }

  .EmptyBox {
    margin: 0 auto;
    width: 64px;
    height: 64px;
    background-color: $white;
  }
}