@import '../../../../assets/styles/variables';

.Item {
  background-color: $white;
  padding-top: 0;
  padding-bottom: 0;
  cursor: pointer;
  align-items: stretch;
  min-height: (56/$df-fs)*$rem;

  > div[class*=Col] {
    padding-top: (6 / $df-fs) * $rem;
    padding-bottom: (6 / $df-fs) * $rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .MultiFieldsWrap {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: $gridSpacingX;
      align-items: center;

      &.CompareWrap {
        grid-template-columns: auto 1fr;
      }
      &.DiscountWrap {
        grid-template-columns: auto 1.6fr 1fr 1.4fr;

        .FieldControl {
          display: grid;
          grid-template-columns: auto 1fr;
          column-gap: ($gridSpacingX/2);
          align-items: center;

          &.LabelAfter {
            grid-template-columns: 1fr auto;
          }
        }
      }
    }

    &.Highlighted {
      background-color: $lightYellow;
    }
  }
  .Checkbox {
    padding: 0;
    margin-top: (-2 / $df-fs) * $rem;
  }
  .ActionsCol {
    text-align: center;
    justify-content: center;
  }
  .Field {
    width: 100%;
  }
  &:nth-of-type(2n) {
    background-color: $whiteGrey;
  }
}
