@import '../../../assets/styles/variables';

.Panel {
  margin-top: $gridSpacingY;
}
.Info {
  color: $secondaryColor;

  > label {
    display: block;
    font-weight: $weightSemibold;
  }
  > p {
    padding-top: $gridSpacingY/4;

    &:empty {
      display: none;
    }
  }

  & + .Info {
    padding-top: $gridSpacingY/2;
  }

  &.VATOption {
    color: $primaryColor;
    padding-top: $gridSpacingY;
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: start;
    font-weight: $weightSemibold;

    .VATInfo {
      padding-top: 0;
      padding-left: $gridSpacingX/2;
      span {
        display: block;
      }
    }
  }
}
