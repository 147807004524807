@import '../../../assets/styles/variables';

.Row {
  padding: (6 / $df-fs) * $rem (8 / $df-fs) * $rem;
  display: grid;
  align-items: center;
  grid-template-columns: (50/$df-fs)*$rem 1fr 1fr 1.6fr 1fr (100/$df-fs)*$rem 2fr (100 / $df-fs) * $rem;
  grid-template-rows: auto;

  &.Group, &.Line {
    grid-template-columns: (50/$df-fs)*$rem 1fr 1fr 2fr 1fr (140 / $df-fs) * $rem (100/$df-fs)*$rem 3fr (100 / $df-fs) * $rem;
  }
  &.Document {
    grid-template-columns: (100/$df-fs)*$rem 1fr 2fr 1.6fr (120/$df-fs)*$rem 3fr (100 / $df-fs) * $rem;
  }
}
.Col {
  display: block;
  word-break: break-word;
  white-space: pre-wrap;
  padding-left: (4 / $df-fs) * $rem;
  padding-right: (4 / $df-fs) * $rem;

  &.Center {
    text-align: center;
    justify-content: center;
  }
}
