@import '../../../../assets/styles/variables';

.Details {
  padding: 0 $gridSpacingX;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: start;
  column-gap: (2 * $gridSpacingX);

  &.IsEditing {
    grid-template-columns: 1fr 1fr;
  }
}
.FieldControl {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  width: 100%;
  min-height: (50/$df-fs)*$rem;

  > label {
    min-width: (110/$df-fs)*$rem;
    width: (160/$df-fs)*$rem;
    font-weight: $weightSemibold;
    margin-right: $gridSpacingX;

    &.AutoLabel {
      min-width: 0;
    }
  }

  .Field {
    display: flex;

    &.RadioGroup {
      flex-direction: row;

      label {
        margin-left: 0;
      }
    }
  }
}