@import '../../variables';

.MuiFormControl-root {
  .MuiInputBase {
    &-input {
      font-family: $fontFamilyBase;
      font-size: $fontSizeBase;
    }
  }
}

