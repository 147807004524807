@import '../../../assets/styles/variables';

.PageMain {
  padding: 0 $gridSpacingX $gridSpacingY;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  grid-row-gap: $gridSpacingX;
}
.PageMainHeader {
  grid-row: 1 / 2;
  grid-column: 1 / 3;
  background-color: $white;
}
.HideCustomer{
  display: none;
}
.PageSide {
  grid-row: 2 / 3;
  grid-column: 1 / 2;
  height: 100%;
  display: flex;
  justify-content: stretch;
  flex-direction: column;
  width: (220/$df-fs)*$rem;
  margin-right: $gridSpacingX;

  .PanelWrap {
    flex: 1 1 0;
    flex-basis: 0;
    display: flex;
    flex-direction: column;
  }

  .PageSideContent {
    flex: 1 1 0;
    flex-basis: 0;
    overflow-y: auto;
    padding-right: 16px;
    margin-right: -16px;
  }

  &.NoData {
    width: 0;
    display: none;
  }
}
.PageContent {
  grid-row: 2 / 3;
  grid-column: 2 / 3;
}