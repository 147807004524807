@import '../../../assets/styles/variables';

.Dialog {
  .DialogContent {
    padding-top: 0 !important;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: $gridSpacingX*2;

    &.DialogContentOneCol {
      grid-template-columns: 1fr;
      padding-left: 0;
      padding-right: 0;
    }
  }
  .DialogActions {
    padding-bottom: $gridSpacingY*1.5;
    padding-left: 24px;
    padding-right: 24px;

    button + button {
      margin-left: $gridSpacingX;
    }

    &.Actions {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.FieldControl {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  width: 100%;
  padding-bottom: $gridSpacingY;

  > label:first-child {
    font-weight: $weightSemibold;
    margin-right: $gridSpacingX;
    width: (130/$df-fs)*$rem;

    &.Flex {
      display: flex;
      align-items: center;
    }
  }
  .Valid {
    input {
      color: $success;
    }
  }
  .InValid {
    input {
      color: $danger;
    }
  }

  &.TaxCodeWrap {
    padding-bottom: 0;
    grid-template-columns: 1fr;

    label {
      width: auto;
    }
  }
}
.WarningIcon {
  color: $warning;
  margin-left: (4/$df-fs)*$rem;
}
