@import '../../assets/styles/variables';

.Panel {
  background-color: $white;
  border-radius: $radiusBase;
  box-shadow: $boxShadow;
  padding: $gridSpacingX;

  .PanelHeading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: (12/$df-fs)*$rem;
  }

  .Title {
    text-transform: uppercase;
    font-weight: $weightBold;
    font-size: (16/$df-fs)*$rem;
  }

  &.Border {
    border: 1px solid $borderColor;
    box-shadow: none;;
  }

  [class*="NoData"] {
    padding: 0;
    text-align: left;
  }

  &.sm {
    .Title {
      font-size: $fontSizeBase;
    }
  }
}
