@import '../../../../assets/styles/variables';

.Dialog {
  .DialogContentWrap{
    width: (640/$df-fs)*$rem;
    max-width: 600px;
  }
  .DialogActions {
    padding-bottom: ($gridSpacingY * 2);
    padding-left: 24px;
    padding-right: 24px;


    button + button {
      margin-left: $gridSpacingX;
    }

    &.DialogActionsFlex {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .DialogActionsLeft {
        flex: 1 1 0;
        flex-basis: 0;
      }
    }
  }
}
.FieldControl {
  padding-bottom: $gridSpacingY;
  > label {
    margin-right: $gridSpacingX;
  }

  &:last-child {
    padding-bottom: 0;
  }
}
.HelpText {
  grid-column: 2 / 3;
  margin-top: 0;
  li {
    font-size: $fontSizeSm;
    color: $textMuted;
    &.Error {
      color: $danger;
    }
  }
}
.Error {
  color: $danger;
}
.Success {
  color: $success;
}
.HelpBlock {
  padding-top: $gridSpacingY;
  p {
    padding-bottom: ($gridSpacingY/2);

    &:empty {
      display: none;
    }
  }
}
.Info {
  font-weight: $weightSemibold;
  padding-bottom: ($gridSpacingY/2);

  > label {
    font-weight: $weightNormal;
  }
}
.ImportConfirmTitle {
  font-weight: $weightSemibold;
  color: $success;
  padding-bottom: $gridSpacingY;
}
.ImageItem {
  position: relative;
  overflow: hidden;
  aspect-ratio: 1/1;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #EEE;
  > img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    z-index: 1;
  }
  .ImageItemActions {
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 3;
    transition: all 250ms ease-in-out;
  }
  .ImageItemOverlay {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: linear-gradient( rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) );
    transition: all 250ms ease-in-out;
  }
  &:hover {
    .ImageItemOverlay {
      opacity: 1;
    }
    .ImageItemActions {
      opacity: 1;
    }
  }
}