@import '../../../assets/styles/variables';

.Panel {
  margin-top: $gridSpacingY*2;

  .UpdateInfo {
    color: $textMuted;
    font-size: $fontSizeBase;
    text-transform: none;
    margin-left: $gridSpacingX;
    font-weight: $weightNormal;
  }
}
.Wrap {
  .Info {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: $gridSpacingX;

    > label {
      display: block;
      font-weight: $weightSemibold;
      color: $textMuted;
    }

    &.InfoSizes {
      grid-template-columns: auto auto 1fr;
      column-gap: $gridSpacingX;

      .Info {
        column-gap: $gridSpacingX/2;
      }
    }
  }
  .Options {
    padding-top: $gridSpacingY/2;
    .Service {
      display: flex;
      align-items: center;

      .ServiceName {
        padding-left: $gridSpacingX/2;
      }

      & + .Service {
        padding-top: $gridSpacingY/2;
      }
    }
  }
  .NoteContent {
    margin-top: $gridSpacingY/2;
    padding: $gridSpacingX/2;
    background-color: $whiteGrey;
    border-radius: $radiusSm;
  }

  .WrapAfterSent {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax((320/$df-fs)*$rem, 1fr));
    column-gap: $gridSpacingX*1.5;
    row-gap: $gridSpacingX/2;
    align-items: stretch;
  }
}
.Actions {
  padding-top: $gridSpacingY;
  text-align: right;
}
