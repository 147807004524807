@import '../../../../assets/styles/variables';

.Toolbar {
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: $gridSpacingX;
  padding-bottom: $gridSpacingY;
  align-items: center;
  margin-top: -(2.2 * $gridSpacingY);
}

.Header {
  font-weight: 600;
  box-shadow: -4px 4px 5px 0px $boxHeader;
  border-bottom: 1px solid transparent;
  background-color: $theadBg;
  color: $theadColor;
}
