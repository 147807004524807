@import '../../assets/styles/variables';

.Wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;

  button + button {
    margin-left: $gridSpacingX;
  }
}
.NameWrap {
  display: flex;
  align-items: center;
  margin-right: $gridSpacingX;
  overflow: hidden;
  flex: 1 1 0;
  flex-basis: 0;

  button + button {
    margin-left: 0;
  }

  button:last-of-type {
    margin-right: $gridSpacingX;
  }

  .Name {
    font-size: $fontSizeH2;
    font-weight: $weightNormal;
  }
}
