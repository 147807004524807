@import '../../../../assets/styles/variables';

.Dialog {
  .DialogContentWrap {
    width: (640 / $df-fs) * $rem;
    max-width: 600px;
  }
  .DialogActions {
    padding-bottom: $gridSpacingY;
    padding-left: 24px;
    padding-right: 24px;

    button + button {
      margin-left: $gridSpacingX;
    }
  }
}
.FieldControl {
  display: flex;
  align-items: center;

  .FieldWrap {
    flex: 1 1 0;
    flex-basis: 0;
  }

  > label {
    margin-right: $gridSpacingX;
  }
}
.Error {
  color: $danger;
}
.Success {
  color: $success;
}
.Info {
  padding-bottom: $gridSpacingY;

  > label {
    font-weight: $weightNormal;
  }
}
