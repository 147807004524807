@import '../../../../../assets/styles/variables';

.QuantityList {
  margin-top: $gridSpacingY/1.5;
  margin-left: $gridSpacingX*1.5;

  padding: 0 $gridSpacingY/2;
  border: 1px solid $secondaryBg;
  border-radius: $radiusBase;

  .Row {
    padding: 0;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr (200/$df-fs)*$rem;
    grid-template-rows: auto;
  }
  .Col {
    display: flex;
    align-items: center;
    word-break: break-word;
    white-space: pre-wrap;
    padding: (8 / $df-fs) * $rem (4 / $df-fs) * $rem;

    &.QuantityCol {
      justify-content: flex-end;
    }
  }

  .Header {
  font-weight: 600;
  box-shadow: -4px 4px 5px 0px $boxHeader;
  border-bottom: 1px solid transparent;
    color: $textMuted;
    text-transform: uppercase;
    font-size: $fontSizeSm;

    .Col {
      padding-bottom: 0;
    }
  }
  .Item {
    background-color: $white;
    padding-top: 0;
    padding-bottom: 0;
    align-items: stretch;

    .Field {
      width: 100%;
    }

    & + .Item {
      border-top: 1px solid $secondaryBg;
    }

    &.ChildItem {
      background-color: $bodyBg;
      margin-left: $gridSpacingX;

      > .Col {
        padding-top: $gridSpacingY/4;
        padding-bottom: $gridSpacingY/4;
      }
      .Child {
        grid-template-columns: auto 1fr;
        column-gap: $gridSpacingX/2;
      }
      .ChildBullet {
        color: $textMuted;
        font-size: $fontSizeSm;
        display: block;
      }

      & + .SingleOrParentItem {
        margin-top: $gridSpacingY/4;
      }
    }
    &.ParentItem {
      border-bottom: 1px solid $secondaryBg;

      .Parent {
        padding-top: (16/$df-fs) * $rem;
        padding-bottom: (16/$df-fs) * $rem;
      }

      & + .ChildItem {
        border-top: none;
        margin-top: $gridSpacingY/4;
      }
    }
  }

  &.Disabled {
    display: hidden;
    position: relative;
    opacity: 0.25;
    user-select: none;

    &:after {
      content: " ";
      position: absolute;
      z-index: 9;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: transparent;
    }
  }
}

.HelpText {
  font-size: $fontSizeSm;
  color: $textMuted;
  margin-left: $gridSpacingX;
  width: (120 / $df-fs) * $rem;
  text-align: right;
}
.Invalid, .Valid {
  color: $danger;
  font-weight: $weightSemibold;
}
.Valid {
  color: $success;
}
.PromotionLevel {
  padding-top: $gridSpacingY;

  &:first-child {
    padding-top: 0;
  }

  & + .PromotionLevel {
    margin-top: $gridSpacingY/2;
    border-top: 1px solid $secondaryBg;
  }
  .LevelTitle {
    font-weight: $weightSemibold;
    padding-bottom: $gridSpacingY/1.5;
    text-transform: uppercase;

    .LevelCount {
      font-weight: $weightNormal;
      color: $textMuted;
      text-transform: none;

      > span {
        color: $primaryColor;
      }
    }
  }
  .FormControl {
    display: flex;
    align-items: center;
    margin-left: 0;
    margin-right: 0;
    user-select: none;

    & + .FormControl {
      padding-top: $gridSpacingY/2;
    }
  }
}
