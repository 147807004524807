@import '../../../../assets/styles/variables';

.ProductItem {
  background-color: $white;
  cursor: pointer;
  user-select: none;

  .Checkbox {
    padding: 0;
  }

  .ActionsCol {
    visibility: hidden;
    opacity: 0;
    transition: opacity ease-in-out 0.2s;
  }

  &:nth-of-type(2n) {
    background-color: $whiteGrey;
  }

  &:hover {
    color: inherit;

    .ActionsCol {
      visibility: visible;
      opacity: 1;
    }
  }
}
