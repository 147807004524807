@import '../../../assets/styles/variables';

.Panel {
  & + .Panel {
    margin-top: $gridSpacingY;
  }
}
.StoreDetails {
  flex: 1 1 0;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  overflow-y: auto;
  padding-right: 16px;
  margin-right: -16px;
}
