@import '../../../assets/styles/variables';

.PageWrap {
  height: 100%;
  padding: 0 $gridSpacingX;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
}
.PageHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: $gridSpacingX;

  button + button {
    margin-left: $gridSpacingX/2;
  }
}
.PageTitle {
  font-size: $fontSizeH2;
  flex: 1 1 0;
  flex-basis: 0;
  margin-left: $gridSpacingX/2;

  .InvoiceIcon {
    margin-left: $gridSpacingX;
  }
}
.PageMain {
  margin-bottom: $gridSpacingX;
  height: 100%;
  flex: 1 1 0;
  flex-basis: 0;
  margin-right: -16px;
  padding-right: 16px;
  overflow-y: auto;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: $gridSpacingX;
  grid-row-gap: $gridSpacingY;
  align-content: start;

  .InfoWrap {
    grid-column: 1 / 3;
  }
  .Content {
    grid-column: 1 / 2;
  }
  .Side {
    grid-column: 2 / 3;
    width: (320/$df-fs)*$rem;
    padding: 0 0 $gridSpacingY;
  }
}
