@import '../../../../../assets/styles/variables';

.Dialog {
  .DialogTitle {
    > h2 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: $weightSemibold;
      text-transform: capitalize;
    }
  }
  .DialogContentWrap {
    width: 50vw;
    min-width: 740px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
  }
  .DialogContent {
    padding-top: 0 !important;
    max-height: 76vh;
    overflow-y: auto;
    flex: 1 1 0;
    flex-basis: 0;
  }
  .DialogActions {
    padding: 0 0 (4/$df-fs)*$rem 0;

    button + button {
      margin-left: $gridSpacingX;
    }

    &.Actions {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .AddressBookSelect {
    display: flex;
    background-color: $inputBg;
    border: 1px solid $inputBorder;
    border-radius: $radiusBase;
    margin-bottom: $gridSpacingY;

    div[class*='MuiSelect-root'] {
      background: none;
      padding: $gridSpacingY/2 $gridSpacingX;
    }

    &:after,
    &:before {
      display: none;
    }
  }
  .AddressMenuItem {
    background-color: $white;
    padding: (12/$df-fs)*$rem (12/$df-fs)*$rem (9/$df-fs)*$rem;
    border: 1px solid $borderSecondaryColor;
    border-radius: $radiusLg;
    margin: 1px 0 (12/$df-fs)*$rem;
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: flex-start;
    column-gap: (8/$df-fs)*$rem;
    font-size: $fontSizeBase;
    cursor: pointer;

    .Icon {
      color: $iconColor;

      &.Checked {
        color: $primaryColor;
      }
    }

    &:hover,
    &.Selected {
      box-shadow: $boxShadow;
      border-color: $white;
      background-color: $white;
    }

    & + .AddressMenuItem {
      margin-top: (12/$df-fs)*$rem;
    }
  }
}

.OtherAddressForm {
  border-top: 1px solid $borderColor;
  padding-top: $gridSpacingY/2;
  padding-bottom: $gridSpacingY/2;

  .OtherAddressFormLabel {
    font-weight: $weightSemibold;
  }
  .CustomInfo {
    padding: $gridSpacingX;
    border-radius: $radiusBase;
    border: 1px solid $borderColor;
    margin-left: (30/$df-fs)*$rem;
  }
  .CustomInfoNote {
    padding: $gridSpacingY 0 $gridSpacingY/2;
    margin-top: $gridSpacingY/2;
    border-top: 1px solid $borderColor;
    color: $textMuted;
  }
}

.FieldControlGroup {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: $gridSpacingX;
  align-items: stretch;
}
.FieldControlGroupBottom {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: $gridSpacingX;
  align-items: stretch;
}
.FieldControl {
  display: grid;
  width: 100%;
  padding-bottom: $gridSpacingY;

  &.FieldControlLabel {
    padding-bottom: 0;
  }
}
