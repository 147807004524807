@import '../../../assets/styles/variables';

.Wrap {
  padding-top: $gridSpacingY/2;
}
.List {
  box-shadow: $boxShadow;
  border-radius: $radiusBase;
  background-color: $white;
}
.Header {
  font-weight: 600;
  box-shadow: -4px 4px 5px 0px $boxHeader;
  border-bottom: 1px solid transparent;
  background-color: $headerBg;
  color: $headerColor;
  border-top-left-radius: $radiusBase;
  border-top-right-radius: $radiusBase;
}
.Body {
  border-bottom-left-radius: $radiusBase;
  border-bottom-right-radius: $radiusBase;
}
.ListFooter {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: $gridSpacingY;

  button + button {
    margin-left: $gridSpacingX/2;
  }
}
.SectionTitle {
  padding-bottom: $gridSpacingY/2;
  text-transform: uppercase;
  font-weight: $weightBold;
  font-size: (16/$df-fs)*$rem;
}
