@import '../../../../assets/styles/variables';

.Wrap {
  padding-bottom: $gridSpacingY;
}
.PromotionResult {
  display: grid;
  grid-template-columns: 4fr 5fr;
  column-gap: $gridSpacingX;
  border-bottom: 1px solid $borderSecondaryColor;
}
.PromotionActive {
  background-color: $secondaryBg;
  padding: $gridSpacingY;
  border-radius: $radiusBase;

  .SelectionItem {
    position: relative;

    .Icon {
      position: absolute;
      top: 0;
      left: -(12/$df-fs)*$rem;;
      z-index: 2;
      font-size: (24/$df-fs)*$rem;
      color: $success;
      background-color: $secondaryBg;
      border-radius: 50%;
    }

    &.LastItem {
      .Icon {
        color: $textMuted;
        font-size: (10/$df-fs)*$rem;
        padding: (5/$df-fs)*$rem (8/$df-fs)*$rem;
      }
      .Item {
        border-left: none;
        p {
          color: $textMuted;
        }
      }
    }
  }
  .Item {
    display: grid;
    grid-template-columns: 1fr auto;
    column-gap: $gridSpacingY;
    padding: 0 0 $gridSpacingY*1.5 $gridSpacingX;
    border-left: 1px solid $success;
    align-items: start;

    p {
      font-size: $fontSizeSm;
    }

    .PromotionLink {
      font-size: $fontSizeBase;
      text-decoration: underline;
      padding-bottom: (2/$df-fs)*$rem;
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }
    }
    .TextExplained {
      color: $textMuted;
      padding-top: $gridSpacingY/4;
    }
  }
}
.HeaderToolbar {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
