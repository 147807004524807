@import '../../../assets/styles/variables';

.Row {
  padding: (12/$df-fs)*$rem;
  display: grid;
  align-items: start;
  grid-template-columns: (120/$df-fs)*$rem (120/$df-fs)*$rem (150/$df-fs)*$rem (100/$df-fs)*$rem (110/$df-fs)*$rem (200/$df-fs)*$rem (100/$df-fs)*$rem (100/$df-fs)*$rem (150/$df-fs)*$rem (200/$df-fs)*$rem (200/$df-fs)*$rem (130/$df-fs)*$rem;
  grid-template-rows: auto;
  grid-column-gap: (8/$df-fs)*$rem;
}
.Col {
  display: block;
  word-break: break-word;
  white-space: pre-wrap;
}

@media screen and (min-width: 1600px) {
  .Row {
    grid-template-columns: 1.2fr 1.2fr 1.5fr 1fr 1.1fr 2fr 1fr 1fr 1.5fr 2fr 2fr 1.3fr;
  }
}
