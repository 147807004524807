@import '../../../assets/styles/variables';


.Row {
  padding: (6/$df-fs)*$rem 0;
  display: grid;
  align-items: center;
  grid-template-columns: (40/$df-fs)*$rem (100/$df-fs)*$rem (150/$df-fs)*$rem (100/$df-fs)*$rem (120/$df-fs)*$rem (120/$df-fs)*$rem (120/$df-fs)*$rem (80/$df-fs)*$rem (100/$df-fs)*$rem (100/$df-fs)*$rem (80/$df-fs)*$rem (100/$df-fs)*$rem (100/$df-fs)*$rem (120/$df-fs)*$rem (100/$df-fs)*$rem (120/$df-fs)*$rem;
  grid-template-rows: auto;
}
.Col {
  display: block;
  word-break: break-word;
  padding-left: (4/$df-fs)*$rem;
  padding-right: (4/$df-fs)*$rem;
  &:nth-child(1){
    position: sticky;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color:#fff;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    z-index: 999;
    > div {
      width: 100%;
      position: relative;
      top: 0px;
    }
  }
  &:nth-child(2){
    position: sticky;
    top: 0;
    left: 2.5rem;
    width: 100%;
    height: 100%;
    align-items: center;
    background-color:#fff;
    box-sizing: border-box;
    z-index: 999;
    display: flex;
    > div {
      width: 100%;
      position: relative;
      top: 0px;
    }
  }
  &:nth-child(3){
    position: sticky;
    top: 0;
    left: 10rem;
    height: 100%;
    width: 100%;
    z-index: 999;
    align-items: center;
    background-color:#fff;
    box-sizing: border-box;
    display: flex;
    > div {
      width: 100%;
      position: relative;
      top: -3px;
    }
  }
  .editDescription {
    width:180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
  }
}

@media screen and (min-width: 1680px) {
  .Row {
     grid-template-columns: (40/$df-fs)*$rem 1fr 1.5fr 1fr 1.2fr 1.2fr 1.2fr (80/$df-fs)*$rem 1fr 1fr 0.8fr 1fr (120/$df-fs)*$rem (120/$df-fs)*$rem (100/$df-fs)*$rem (120/$df-fs)*$rem;
  }
}
