@import '../../variables';

.Toastify {
  &__toast {
    color: $textColor;

    &-body {
      font-size: $fontSizeBase;
    }

    &--success {
      background: $white;
      color: $textColor;

      .Toastify__toast-body {
        color: $success;
      }
    }
    &--warning {
      background: $white;
      color: $textColor;

      .Toastify__toast-body {
        color: $warning;
      }
    }
    &--error {
      background: $white;
      color: $danger;

      .Toastify__toast-body {
        color: $danger;
      }
    }
  }
  &__close {
    &-button {
      color: $textColor;
    }
  }
}
