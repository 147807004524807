@import '../../../../assets/styles/variables';

.Col {
  display: block;
  word-break: break-word;
  white-space: pre-wrap;
  padding-left: $gridSpacingX/2;
  padding-right: $gridSpacingX/2;

  color: $textColor;
  font-size: $fontSizeBase;
  font-weight: $weightNormal;
}
.Row {
  padding: $gridSpacingX/2 $gridSpacingX/4;
  display: grid;
  align-items: center;
  grid-template-columns: 2fr 2fr 1fr 0.8fr 1.2fr 1fr 1.5fr;
  grid-template-rows: auto;

  & + .Row {
    border-top: 1px solid $borderColor;
  }

  &:last-child {
    border-bottom: none;
  }

  &.Header {
  font-weight: 600;
  box-shadow: -4px 4px 5px 0px $boxHeader;
  border-bottom: 1px solid transparent;
    padding-top: $gridSpacingY/1.5;
    padding-bottom: $gridSpacingY/1.5;
    background-color: $theadBg;
    border-bottom: none;
    border-radius: $radiusBase;

    .Col {
      color: $headerColor;
      text-transform: uppercase;
      font-size: $fontSizeSm;
      font-weight: $weightNormal;
    }
  }

  &.IsEditing {
    grid-template-columns: 2fr 2fr (132/$df-fs)*$rem (60/$df-fs)*$rem 1.3fr (124/$df-fs)*$rem 1.5fr (30/$df-fs)*$rem;
  }

  &.LineDiscountRow,
  &.DiscountRow {
    grid-template-columns: 2fr 7.5fr;
    border-top: none;
    padding-top: 0;

    .GroupCol {
      background-color: $borderColor;
      border-radius: $radiusBase;
      padding: $gridSpacingY/2;
      display: grid;
      grid-template-columns: 1fr auto;
      column-gap: $gridSpacingX;
    }
  }

  &.DiscountRow {
    grid-template-columns: 8.7fr;
  }
}
