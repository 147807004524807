@import '../../assets/styles/variables';

.InputWrap {
  width: 100%;

  .Input {
    div[class*=MuiInputBase-root] {
      padding-left: (4/$df-fs)*$rem;
      padding-right: (4/$df-fs)*$rem;
    }
    input {
      text-align: center;
    }
  }
}
