@import '../../../assets/styles/variables';

.Details {
  padding: 0 $gridSpacingX;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: start;
  column-gap: (2 * $gridSpacingX);

  &.Editing {
    grid-template-columns: repeat(2, 1fr) auto auto;
    
    .ActivityCol {
      justify-content: end;
  
      .FieldControl {
        > label {
          width: auto;
        }
      }
      .ActivityBtnControl {
        grid-template-columns: 1fr;
        text-align: right;
      }
    }
  }
}
.FieldControl {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: start;
  width: 100%;
  min-height: 30px;
  word-break: break-word;

  > label {
    font-weight: $weightSemibold;
    margin-right: $gridSpacingX;
    white-space: nowrap;

    &.Flex {
      display: flex;
      align-items: center;
    }
  }

  &.AutolabelFieldControl {
    > label {
      width: auto;
    }
  }

  & + .FieldControl {
    padding-top: $gridSpacingY;
  }
}
.ActiveWrap {
  > label {
    margin-right: $gridSpacingX;
    &:empty {
      display: none;
    }
  }
}
.WarningIcon {
  color: $warning;
  margin-left: (4 / $df-fs) * $rem;
}
