@import '../../../assets/styles/variables';

.Panel {
  margin-top: $gridSpacingY*2;
}
.Inner {
  overflow-y: auto;
  max-height: (240/$df-fs)*$rem;
}
.Bullet {
  display: block;
  width: 9px;
  height: 9px;
  background-color: $secondaryColor;
  border-radius: 50%;
  margin-top: (6/$df-fs)*$rem;
}
.EventList {
  position: relative;
  z-index: 0;
  min-height: 100%;

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    height: 100%;
    left: 4px;
    width: 1px;
    background-color: $textMuted;
    opacity: 0.33;
  }
}
.EventItem {
  display: flex;
  padding-bottom: (1.5 * $gridSpacingY);

  .EventDetails {
    padding-left: ($gridSpacingX/2);
  }
  .EventItem {
    flex: 1 1 0;
    padding-left: (2 * $gridSpacingX);
    word-break: break-word;
  }
  .EventTime {
    color: $textMuted;
    font-size: $fontSizeSm;
    font-weight: $weightBold;
    line-height: 1;
    display: flex;
    align-items: center;
  }
  .EventStatus {
    font-size: $fontSizeSm;
    margin-left: $gridSpacingX/2;
  }
  .EventTitle {
    padding-top: (4/$df-fs)*$rem;
    font-weight: $weightMedium;
  }
}
