@import '../../../assets/styles/variables';

.Dialog {
  .DialogContentWrap {
    width: 90vw;
    max-width: 960px;
  }
  .DialogContent {
    padding-top: 0 !important;
  }
}
