@import '../../../../../assets/styles/variables';

.FieldControl {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  justify-content: end;
  width: 100%;
  padding-bottom: $gridSpacingY;

  > label {
    font-weight: $weightSemibold;
    margin-right: $gridSpacingX;

    &.Flex {
      display: flex;
      align-items: center;
    }
  }
  .Valid {
    input {
      color: $success;
    }
  }
  .InValid {
    input {
      color: $danger;
    }
  }

  &.OneColumn {
    grid-template-columns: 1fr;
  }
}
.WarningIcon {
  color: $warning;
  margin-left: (4/$df-fs)*$rem;
}

.FieldControlWrap {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: $gridSpacingX;
  align-items: center;
  width: 100%;

  .Field {
    display: flex;
  }
}
.NoteItem {
  padding: $gridSpacingX/2 0;

  .NoteInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .NoteOwner {
    font-size: $fontSizeSm;
  }
  .NoteDate {
    color: $textMuted;
    font-size: $fontSizeSm;
    padding-left: $gridSpacingX/2;
    margin-left: $gridSpacingX/2;
    border-left: 1px solid $secondaryBg;
  }
  .NoteContent {
    margin-top: $gridSpacingY/4;
    padding: $gridSpacingY/2;
    background-color: $secondaryBg;
    border-radius: $radiusBase;
  }
  .NoteActions {
    align-self: flex-end;
  }
}
