@import '../../../assets/styles/variables';

.Row {
  padding: (6 / $df-fs) * $rem (8 / $df-fs) * $rem;
  display: grid;
  align-items: center;
  grid-template-columns: (120/$df-fs)*$rem 1.6fr 2fr 1fr 1fr;
  grid-template-rows: auto;

  &.HasFocusProduct {
    grid-template-columns: (120/$df-fs)*$rem 1.6fr 2fr 1fr 1fr 1fr 1fr;
  }
}
.Col {
  display: block;
  word-break: break-word;
  white-space: pre-wrap;
  padding-left: (4 / $df-fs) * $rem;
  padding-right: (4 / $df-fs) * $rem;

  &.Center {
    text-align: center;
    justify-content: center;
  }
}
