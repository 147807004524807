@import '../../../../../assets/styles/variables';

.ProductListCol {
  word-break: break-word;
  white-space: pre-wrap;
  padding-left: $gridSpacingX/2;
  padding-right: $gridSpacingX/2;

  color: $textColor;
  font-size: $fontSizeBase;
  font-weight: $weightNormal;

  &.Center {
    align-items: center;
  }
}
.ProductListRow {
  padding: $gridSpacingX/1.5 0;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr (150/$df-fs)*$rem (60/$df-fs)*$rem (150/$df-fs)*$rem (48/$df-fs)*$rem;
  grid-template-rows: auto;
  border-bottom: 1px solid $secondaryBg;

  &.ProductListRowHeader {
    border-bottom: none;
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: $white;

    .ProductListCol {
      color: $textMuted;
      text-transform: uppercase;
      font-size: $fontSizeSm;
      font-weight: $weightNormal;
    }
  }
}
