@import '../../../../../assets/styles/variables';

.FormGroup {
  padding-left: (16/$df-fs)*$rem;

  label, span {
    color: $textMuted;
    font-size: $fontSizeBase;
  }

  .FieldWrap {
    padding-top: $gridSpacingY/2;
  }
}
