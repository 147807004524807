@import '../../../../assets/styles/variables';

.Item {
  background-color: $white;
  padding-top: 0;
  padding-bottom: 0;
  cursor: pointer;
  align-items: stretch;

  > div[class*=Col] {
    padding-top: (6 / $df-fs) * $rem;
    padding-bottom: (6 / $df-fs) * $rem;
    display: flex;
    align-items: center;

    &.Highlighted {
      background-color: $lightYellow;
    }
  }
  .Checkbox {
    padding: 0;
    margin-top: (-2 / $df-fs) * $rem;
  }
  .ActionsCol {
    text-align: center;
  }
  .Field {
    width: 100%;
  }
  &:nth-of-type(2n) {
    background-color: $whiteGrey;
  }
}
