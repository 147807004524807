@import '../../../assets/styles/variables';

.Row {
  padding: (6/$df-fs)*$rem (8/$df-fs)*$rem;
  display: grid;
  align-items: center;
  grid-template-columns: (40/$df-fs)*$rem 1.5fr 2fr 1.5fr 1fr 1.7fr (80/$df-fs)*$rem 2fr (100/$df-fs)*$rem;
  grid-template-rows: auto;
}
.Col {
  display: block;
  word-break: break-word;
  white-space: pre-wrap;
  padding-left: (4 / $df-fs) * $rem;
  padding-right: (4 / $df-fs) * $rem;
}
