@import '../../assets/styles/variables';

.FileUpload {
  background-color: $white;
  border: 2px dashed $borderColor;
  border-radius: $radiusBase;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: (32/$df-fs)*$rem;
}
.Highlight {
  background-color: $blueGrey;
}
.FileInput {
  display: none;
}
.HelpBlock {
  color: $textMuted;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: center;
  justify-self: center;

  .Icon {
    font-size: (64/$df-fs)*$rem;
    line-height: 1;
  }

  .HelpText {
    font-size: $fontSizeBase;
    padding-top: $gridSpacingY;
  }
}
