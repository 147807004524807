@import '../../../../assets/styles/variables';

.Dialog {
  .DialogContentWrap {
    width: (640 / $df-fs) * $rem;
    max-width: 600px;
  }
  .DialogActions {
    padding-bottom: $gridSpacingY;
    padding-left: 24px;
    padding-right: 24px;

    button + button {
      margin-left: $gridSpacingX;
    }
  }
}
.FormControl {
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: (2 * $gridSpacingX);
  padding-bottom: $gridSpacingY;

  .FieldControl {
    display: flex;
    align-items: center;

    > label {
      margin-right: $gridSpacingX;
    }
  }
}
.Error {
  color: $danger;
}
.Success {
  color: $success;
}
.HelpBlock {
  padding-top: $gridSpacingY;
  p {
    padding-bottom: ($gridSpacingY/2);

    &:empty {
      display: none;
    }
  }
}
.Info {
  padding-bottom: $gridSpacingY;

  > label {
    font-weight: $weightNormal;
  }
}
