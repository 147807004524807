@import '../../../assets/styles/variables';

.Row {
  padding: (12/$df-fs)*$rem;
  display: grid;
  align-items: start;
  grid-template-columns: 1fr 1.4fr 1.1fr 1.5fr 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-column-gap: (8/$df-fs)*$rem;
}
.Col {
  display: block;
  word-break: break-word;
  white-space: pre-wrap;
}
