@import '../../../../assets/styles/variables';

.Item {
  background-color: $white;
  cursor: pointer;
  user-select: none;

  .DangerIcon {
    color: $danger;
    margin-left: (4/$df-fs)*$rem;
    line-height: 1;
  }

  &:nth-of-type(2n) {
    background-color: $whiteGrey;
  }

  &:hover {
    color: inherit;
  }
}
.CodeCol {
  display: flex;
  align-items: center;
}
