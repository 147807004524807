@import '../../../assets/styles/variables';

.Row {
  padding: (12/$df-fs)*$rem;
  display: grid;
  align-items: start;
  grid-template-columns: 1.2fr 1.2fr 1.4fr 1.8fr 1.4fr 1.4fr 1.4fr (160/$df-fs)*$rem 1.3fr;
  grid-template-rows: auto;
  grid-column-gap: (8/$df-fs)*$rem;
}
.Col {
  display: block;
  word-break: break-word;
  white-space: pre-wrap;
}
