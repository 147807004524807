@import '../../../../assets/styles/variables';

.Item {
  background-color: $white;
  padding-top: 0;
  padding-bottom: 0;
  cursor: pointer;

  > div[class*=Col] {
    padding-top: (6 / $df-fs) * $rem;
    padding-bottom: (6 / $df-fs) * $rem;

    &.Highlighted {
      background-color: $lightYellow;
    }
    .FieldsWrap {
      display: grid;
      align-items: center;
      grid-template-columns: 1fr auto;
      column-gap: ($gridSpacingX/2);
    }
  }
  .ActionsCol {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .Checkbox {
    padding: 0;
    margin-top: (-2 / $df-fs) * $rem;
  }

  &:nth-of-type(2n) {
    background-color: $whiteGrey;
    > div[class*=Col] {
    &:nth-child(3) { 
      background:$whiteGrey;
    }
    &:nth-child(1) { 
      background:$whiteGrey;
    }
    &:nth-child(2) { 
      background:$whiteGrey;
    }
  }
  }

  &.Highlighted {
    background-color: $lightYellow !important;
  }

  .RemoveImages {
    > span {
      display: flex;
      align-items: center;
      justify-content: space-between;
      > a {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      margin-bottom: .25rem;
    }
  }
  .ViewImages {
    > span {
      display: flex;
      > a {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      margin-bottom: .25rem;
    }
  }
}
