@import '../../../../assets/styles/variables';

.Item {
  background-color: $white;
  padding-top: 0;
  padding-bottom: 0;
  align-items: stretch;

  &:nth-of-type(2n) {
    background-color: $whiteGrey;
  }
}
